
import { defineComponent, reactive, onMounted, computed } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";
import useGateway from "@/core/services/compositions/enum/useGateway";
import useChannel from "@/core/services/compositions/enum/useChannelByHash";
import useBillType from "@/core/services/compositions/enum/useBillType";
import useAdminScheme from "@/core/services/compositions/enum/useAdminScheme";
import { checkRole } from '@/router/authentication'
import usePaymentTerminal from "@/core/services/compositions/enum/usePaymentTerminal";

interface Filter {
  name: string;
  email: string;
  gateway: string;
  bcn: string;
  type: string;
  channel: string;
  admin_scheme: string;
  excluded_terminal: string;
}

export default defineComponent({
  name: "filter-client",
  components: {},
  setup(props, context) {
    const { query } = useQuery();
    const { gateway } = useGateway();
    const { channel } = useChannel();
    const { billType } = useBillType();
    const { adminScheme } = useAdminScheme();
    const { paymentTerminal } = usePaymentTerminal();

    const data = reactive<Filter>({
      name: query.value.name || '',
      email: query.value.email || '',
      gateway: query.value.gateway || '',
      bcn: query.value.bcn || '',
      type: query.value.type || '',
      channel: query.value.channel || '',
      admin_scheme: query.value.admin_scheme || '',
      excluded_terminal: query.value.excluded_terminal || '',
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.name = '';
      data.email = '';
      data.gateway = '';
      data.bcn = '';
      data.type = '';
      data.channel = '';
      data.admin_scheme = '';
      data.excluded_terminal = '';
    };

    return {
      data,
      filter,
      reset,
      gateway,
      billType,
      channel,
      adminScheme,
      checkRole,
      paymentTerminal,
    };
  }
});
