
import { reactive, defineComponent, computed, ref, onMounted, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import useInstitutionList from "@/core/services/compositions/institution/useInstitutionList";
import { useI18n } from "vue-i18n";
import {
  useQuery,
  useMutateQuery,
  inputNumbersOnly,
  showErrorPopup,
  showSuccessPopup,
} from "@/core/helpers/common-helper";
import Swal from "sweetalert2";
import { ElNotification } from 'element-plus'
import TableSkeleton from "@/components/skeleton/Table.vue";
import Pagination from "@/components/Pagination.vue";
import moment from 'moment';
import useChannel from "@/core/services/compositions/enum/useChannel";
import useBillType from "@/core/services/compositions/enum/useBillType";
import useAdminScheme from "@/core/services/compositions/enum/useAdminScheme";
import ApiService from "@/core/services/ApiService";
import { checkPermission } from '@/router/authentication';
import FilterInstitution from "./filter-institution.vue";
import useGateway from "@/core/services/compositions/enum/useGateway";
import useInstitution from "@/core/services/compositions/enum/useInstitution";
import { MenuComponent } from "@/assets/ts/components";
import { checkRole } from '@/router/authentication'
import usePaymentTerminal from "@/core/services/compositions/enum/usePaymentTerminal";

interface Institution {
  name: string;
  address: string;
  phone: string;
  email: string;
  is_active: boolean;
  gateway: string;
  channels: Array<any>;
}

interface Channel {
  channel_id: string;
  bcn: string;
  bcn_type: string;
  admin_fee: number;
  admin_fee_shared: number;
  sponsor_fee_shared: number;
  admin_scheme: string;
  bill_mode: string;
  is_active: boolean;
}

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterInstitution
  },
  setup() {
    // init
    const { t } = useI18n();
    const router = useRouter();
    const { query } = useQuery();
    const { channel } = useChannel();
    const { billType } = useBillType();
    const { adminScheme } = useAdminScheme();
    const { paymentTerminal } = usePaymentTerminal();
    const editInstitutionKey = ref(null);
    const editChannelKey = ref();
    const currentScope = ref();
    const currentChannel = ref();
    const onProgress = ref(false);
    const { gateway } = useGateway();
    const dialogSaveChannelInstitutionVisible = ref(false);

    // breadcrumb
    setNewPageBreadcrumbs(t("management.institution.title"), [
      { title: t("management.institution.title") }
    ]);

    const paramEditInstitution = reactive<Institution>({
      name: '',
      address: '',
      phone: '',
      email: '',
      is_active: false,
      gateway: '',
      channels: [
        {
          channel_id: '',
          bcn: '',
          bcn_type: '',
          admin_fee: '',
          admin_fee_shared: '',
          sponsor_fee_shared: '',
          admin_scheme: '',
          bill_mode: '',
        }
      ]
    });

    const paramEditChannel = reactive<{}>({});

    const gotoCreate = () => {
      router.push({ name: "app.management.institution.create" });
    };

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      name: query.value.name || '',
      email: query.value.email || '',
      bcn: query.value.bcn || '',
      gateway: query.value.gateway || '',
      channel: query.value.channel || '',
      admin_scheme: query.value.admin_scheme || '',
      excluded_terminal: query.value.excluded_terminal || '',
      type: query.value.type || '',
      order_by: 'created_at',
      order: 'DESC',
    }));

    const { data, isValidating, mutate: refreshList, error } = useInstitutionList(() => fetchParams.value);

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    const form = reactive({
      name: '',
      email: '',
      gateway: '',
      channel: '',
      admin_scheme: '',
      excluded_terminal: '',
      bcn: '',
      type: '',
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    });

    const filtered = (filters) => {
      Object.assign(form, filters);
      mutateQuery({
        page: 1,
        name: form.name,
        email: form.email,
        gateway: form.gateway,
        channel: form.channel,
        admin_scheme: form.admin_scheme,
        excluded_terminal: form.excluded_terminal,
        bcn: form.bcn,
        type: form.type,
        order_by: 'created_at',
        order: 'DESC',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    const showSaveDialog = (scope, channel) => {
      dialogSaveChannelInstitutionVisible.value = true;

      currentScope.value = scope;
      currentChannel.value = channel;
    };

    const fetchParamInstitution = computed(() => ({
      name: paramEditChannel['institution_' + editChannelKey.value],
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { institution, isValidating: isValidatingInstitution } = useInstitution(() => fetchParamInstitution.value);

    const setEditableInstitution = (scope) => {
      editInstitutionKey.value = scope.row.code

      paramEditInstitution['name_' + scope.row.code] = scope.row.name;
      paramEditInstitution['address_' + scope.row.code] = scope.row.address;
      paramEditInstitution['phone_' + scope.row.code] = scope.row.phone;
      paramEditInstitution['email_' + scope.row.code] = scope.row.email;
      paramEditInstitution['is_active_' + scope.row.code] = scope.row.is_active;
      paramEditInstitution['gateway_' + scope.row.code] = scope.row.gateway;
      paramEditInstitution['excluded_terminals_' + scope.row.code] = scope.row.excluded_terminals;
    };

    const cancelEditableInstitution = () => {
      paramEditInstitution['name_' + editInstitutionKey.value] = '';
      paramEditInstitution['address_' + editInstitutionKey.value] = '';
      paramEditInstitution['phone_' + editInstitutionKey.value] = '';
      paramEditInstitution['email_' + editInstitutionKey.value] = '';
      paramEditInstitution['is_active_' + editInstitutionKey.value] = '';
      paramEditInstitution['gateway_' + editInstitutionKey.value] = '';
      paramEditInstitution['excluded_terminals_' + editInstitutionKey.value] = '';

      editInstitutionKey.value = null;
    };

    const saveEditInstitution = (scope) => {
      onProgress.value = true;

      const payload = {
        client_id: scope.row.client.hash,
        name: paramEditInstitution['name_' + scope.row.code],
        email: paramEditInstitution['email_' + scope.row.code],
        address: paramEditInstitution['address_' + scope.row.code],
        phone: paramEditInstitution['phone_' + scope.row.code],
        gateway: paramEditInstitution['gateway_' + scope.row.code],
        excluded_terminals: paramEditInstitution['excluded_terminals_' + scope.row.code],
        is_active: paramEditInstitution['is_active_' + scope.row.code],
      };

      Swal.fire({
        showClass: {
          popup: "mumtaz-success-popup"
        },
        icon: "warning",
        title: 'Warning',
        text: 'Are you sure you want to save this update?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.setHeader();
          ApiService.put(
            `app/entity/institution/` + scope.row.code,
            payload as any
          )
            .then((data) => {
              showSuccessPopup({
                title: 'Status',
                text: 'Success'
              });
            })
            .catch(({ response }) => {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            })
            .finally(() => {
              onProgress.value = false;
              cancelEditableInstitution();
              refreshList();
            });
        } else {
          onProgress.value = false;
        }
      });
    };

    const setEditableChannel = (scope, channel) => {
      editChannelKey.value = scope.row.code + '_' + channel.pivot.bcn

      paramEditChannel['channel_' + editChannelKey.value] = channel.channel;
      paramEditChannel['bcn_' + editChannelKey.value] = channel.pivot.bcn;
      paramEditChannel['bcn_type_' + editChannelKey.value] = channel.pivot.bcn_type;
      paramEditChannel['admin_fee_' + editChannelKey.value] = channel.pivot.admin_fee;
      paramEditChannel['admin_fee_shared_' + editChannelKey.value] = channel.pivot.admin_fee_shared;
      paramEditChannel['sponsor_fee_shared_' + editChannelKey.value] = channel.pivot.sponsor_fee_shared;
      paramEditChannel['admin_scheme_' + editChannelKey.value] = channel.pivot.admin_scheme;
      paramEditChannel['is_active_' + editChannelKey.value] = channel.pivot.disabled_at == null ? false : true;
    };

    const cancelEditableChannel = () => {
      paramEditChannel['institution_' + editChannelKey.value] = '';
      paramEditChannel['channel_' + editChannelKey.value] = '';
      paramEditChannel['bcn_' + editChannelKey.value] = '';
      paramEditChannel['bcn_type_' + editChannelKey.value] = '';
      paramEditChannel['admin_fee_' + editChannelKey.value] = '';
      paramEditChannel['admin_fee_shared_' + editChannelKey.value] = '';
      paramEditChannel['sponsor_fee_shared_' + editChannelKey.value] = '';
      paramEditChannel['admin_scheme_' + editChannelKey.value] = '';
      paramEditChannel['is_active_' + editChannelKey.value] = '';

      editChannelKey.value = '';
    };

    const saveEditChannel = () => {
      onProgress.value = true;
      dialogSaveChannelInstitutionVisible.value = false;

      const payload = {
        channel_id: currentChannel.value.id,
        bcn: currentChannel.value.pivot.bcn,
        bcn_type: paramEditChannel['bcn_type_' + editChannelKey.value],
        admin_fee: paramEditChannel['admin_fee_' + editChannelKey.value],
        admin_fee_shared: paramEditChannel['admin_fee_shared_' + editChannelKey.value],
        sponsor_fee_shared: paramEditChannel['sponsor_fee_shared_' + editChannelKey.value],
        admin_scheme: paramEditChannel['admin_scheme_' + editChannelKey.value],
        bill_mode: paramEditChannel['bill_mode_' + editChannelKey.value],
        is_active: paramEditChannel['is_active_' + editChannelKey.value],
        edited_channel_id: paramEditChannel['channel_' + editChannelKey.value],
        institution: paramEditChannel['institution_' + editChannelKey.value],
      };

      ApiService.setHeader();
      ApiService.patch(
        `app/entity/institution/` + currentScope.value.row.code,
        payload as any
      )
        .then((data) => {
          showSuccessPopup({
            title: 'Status',
            text: 'Success'
          });
        })
        .catch(({ response }) => {
          showErrorPopup({
            title: response.data.rc,
            text: response.data.message,
            message: response.data.message,
          })
        })
        .finally(() => {
          onProgress.value = false;
          cancelEditableChannel();
          refreshList();
        });
    };

    const searchInstitution = (val) => {
      paramEditChannel['institution_' + editChannelKey.value] = val;
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      data,
      isValidating,
      gotoCreate,
      handleChangePage,
      moment,
      inputNumbersOnly,
      channel,
      billType,
      adminScheme,
      paramEditInstitution,
      paramEditChannel,
      editInstitutionKey,
      editChannelKey,
      setEditableInstitution,
      cancelEditableInstitution,
      saveEditInstitution,
      setEditableChannel,
      cancelEditableChannel,
      saveEditChannel,
      onProgress,
      checkPermission,
      filtered,
      gateway,
      institution,
      searchInstitution,
      isValidatingInstitution,
      dialogSaveChannelInstitutionVisible,
      showSaveDialog,
      checkRole,
      paymentTerminal
    };
  },
  methods: {}
});
